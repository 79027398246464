var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isPreview && _vm.withLink)?_c('a',{staticClass:"thumbnail-wrapper",style:({
    width: _vm.emptyWidth + 'px',
    height: _vm.emptyHeight + 'px',
  }),attrs:{"href":_vm.originalPath,"target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.thumbnailPath),expression:"thumbnailPath"}],key:_vm.thumbnailKey,staticClass:"thumbnail-picture",style:(_vm.imgStyle)})]):(_vm.isPreview && !_vm.withLink)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.thumbnailPath),expression:"thumbnailPath"}],key:_vm.thumbnailKey,staticClass:"thumbnail-picture",staticStyle:{}}):_c('span',{class:{
    'thumbnail-picture': true,
    'thumbnail-empty': true,
    square: _vm.square
  },style:({
    width: _vm.emptyWidth + 'px',
    height: _vm.emptyHeight + 'px',
  })})}
var staticRenderFns = []

export { render, staticRenderFns }