var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"unselectable",on:{"mouseenter":function($event){_vm.isFrameNumberVisible = true},"mouseout":function($event){_vm.isFrameNumberVisible = false}}},[_c('div',{staticClass:"progress-wrapper",style:({
      'background-size': _vm.backgroundSize
    })},[_c('progress',{ref:"progress",attrs:{"value":"0","min":"0"},on:{"click":_vm.onProgressClicked,"mousedown":function($event){return _vm.startProgressDrag($event)}}}),_vm._l((_vm.annotations),function(annotation,index){return _c('span',{key:("annotation-" + index),staticClass:"annotation-mark",style:({
        left: _vm.getAnnotationPosition(annotation) + 'px',
        width: Math.max(_vm.frameSize - 1, 5) + 'px'
      }),on:{"mouseenter":function($event){_vm.isFrameNumberVisible = true},"mouseleave":function($event){_vm.isFrameNumberVisible = true},"click":function($event){return _vm._emitProgressEvent(annotation)}}})}),_c('span',{staticClass:"frame-number",style:({
        display: _vm.isFrameNumberVisible ? null : 'none',
        left: _vm.frameNumberLeftPosition + 'px'
      })},[_vm._v(" "+_vm._s(_vm.hoverFrame)+" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }