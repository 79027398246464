import { render, staticRenderFns } from "./ValidationCell.vue?vue&type=template&id=70f67371&scoped=true&"
import script from "./ValidationCell.vue?vue&type=script&lang=js&"
export * from "./ValidationCell.vue?vue&type=script&lang=js&"
import style0 from "./ValidationCell.vue?vue&type=style&index=0&id=70f67371&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f67371",
  null
  
)

export default component.exports