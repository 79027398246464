var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'delete-modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.text))]),(_vm.isError)?_c('p',{staticClass:"is-danger"},[_vm._v(_vm._s(_vm.errorText))]):_vm._e(),_c('p',{staticClass:"has-text-right"},[_c('a',{class:{
              button: true,
              'is-danger': true,
              'is-loading': _vm.isLoading
            },on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.$t("main.confirmation"))+" ")]),_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.cancel"))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }