var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box content"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("tasks.comment_image"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("tasks.select_file"))+" ")]),_c('file-upload',{ref:"file-field",attrs:{"label":_vm.$t('main.select_file'),"accept":_vm.extensions,"multiple":true},on:{"fileselected":_vm.onFileSelected}}),(_vm.isError)?_c('p',{staticClass:"error"},[_vm._v(" $t('main.add') ")]):_vm._e(),(_vm.isMovie)?_c('p',{staticClass:"mt1"},[_vm._v(" Or: ")]):_vm._e(),(_vm.isMovie)?_c('p',[_c('button',{class:{
            button: true,
            'is-loading': _vm.isAnnotationLoading
          },on:{"click":function($event){return _vm.$emit('add-snapshots')}}},[_vm._v(" "+_vm._s(_vm.$t('main.attach_snapshots'))+" ")])]):_vm._e(),_c('p',{staticClass:"has-text-right"},[_c('a',{class:{
            button: true,
            'is-primary': true,
            'is-loading': _vm.isLoading,
            'is-disabled': _vm.forms == undefined
          },on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" "+_vm._s(_vm.$t("main.confirmation"))+" ")]),_c('button',{staticClass:"button is-link",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.cancel"))+" ")])]),(_vm.forms)?_c('p',{staticClass:"upload-previews"},[_vm._l((_vm.forms),function(form,i){return [_c('hr',{key:'separator-' + i}),(_vm.isImage(form))?_c('img',{key:i,attrs:{"alt":"uploaded file","src":_vm.getURL(form)}}):(_vm.isVideo(form))?_c('video',{key:i,staticClass:"is-fullwidth",attrs:{"preload":"auto","controls":"","loop":"","muted":"","src":_vm.getURL(form)},domProps:{"muted":true}}):(_vm.isPdf(form))?_c('iframe',{key:i,staticClass:"is-fullwidth",attrs:{"frameborder":"0","src":_vm.getURL(form)}}):_vm._e()]})],2):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }