var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{ref:"cell",class:{
    validation: _vm.selectable,
    selected: _vm.selectable & _vm.selected
  },style:({
    'border-left': _vm.isBorder ? '1px solid ' + _vm.column.color : 'none',
    'background': _vm.isBorder ? _vm.getBackground() : 'transparent',
    'left': _vm.left
  }),on:{"mouseover":_vm.onMouseOver,"mouseout":_vm.onMouseOut,"click":_vm.onClick}},[(!_vm.minimized)?_c('div',{staticClass:"wrapper"},[(_vm.task)?_c('span',[_c('span',{staticClass:"tag",style:({
          background: _vm.backgroundColor,
          color: _vm.color
        }),attrs:{"title":_vm.taskStatus.name}},[_vm._v(" "+_vm._s(_vm.taskStatus.short_name)+" ")]),(!_vm.isCurrentUserClient)?_c('span',{staticClass:"priority"},[_vm._v(" "+_vm._s(_vm.priority)+" ")]):_vm._e(),(!_vm.isCurrentUserClient && _vm.isCastingReady)?_c('span',{staticClass:"casting-status",attrs:{"title":_vm.castingTitle}},[_c('img',{attrs:{"src":require("@/assets/icons/casting-ready.png"),"width":"20"}})]):(!_vm.isCurrentUserClient)?_c('span',{staticClass:"casting-status",attrs:{"title":_vm.castingTitle}},[_vm._v("         ")]):_vm._e()]):_vm._e(),_c('span'),_vm._l((_vm.assignees),function(personId){return (_vm.isAssignees && !_vm.isCurrentUserClient)?_c('span',{key:'avatar-' + personId,staticClass:"avatar has-text-centered",style:({
        background: _vm.personMap.get(personId).color,
        width: '20px',
        height: '20px',
        'font-size': '10px'
      }),attrs:{"title":_vm.personMap.get(personId).full_name}},[(_vm.personMap.get(personId).has_avatar)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.avatarPath(personId)),expression:"avatarPath(personId)"}],key:_vm.avatarKey(personId),attrs:{"width":"20","height":"20"}}):_c('span',[_vm._v(" "+_vm._s(_vm.personMap.get(personId).initials)+" ")])]):_vm._e()})],2):_c('div',{staticClass:"wrapper"},[_c('span',{staticClass:"tag",style:({
        background: _vm.backgroundColor,
        color: _vm.color
      })},[_vm._v("   ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }