var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:(_vm.tooltipOptions),expression:"tooltipOptions",modifiers:{"right":true}}],staticClass:"flexrow production-name"},[(!_vm.noLink)?_c('router-link',{staticClass:"flexrow flexrow-item",attrs:{"to":_vm.productionRoute}},[(_vm.withAvatar)?_c('div',{staticClass:"flexrow-item avatar has-text-centered",style:({
       background: _vm.getAvatarColor(_vm.entry),
       width: _vm.size + 'px',
       height: _vm.size + 'px',
       'font-size': (_vm.size - 15) + 'px',
       'line-height': _vm.size + 'px'
    })},[(!_vm.entry.has_avatar)?_c('span',[_vm._v(" "+_vm._s(_vm.generateAvatar(_vm.entry))+" ")]):_c('span',[_c('img',{attrs:{"src":_vm.getThumbnailPath(_vm.entry)}})])]):_vm._e(),(!_vm.onlyAvatar)?_c('span',{staticClass:"flexrow-item"},[_vm._v(" "+_vm._s(_vm.entry.name)+" ")]):_vm._e()]):_c('div',{staticClass:"flexrow flexrow-item"},[(_vm.withAvatar)?_c('div',{staticClass:"flexrow-item avatar has-text-centered",style:({
       background: _vm.getAvatarColor(_vm.entry),
       width: _vm.size + 'px',
       height: _vm.size + 'px',
       'font-size': (_vm.size - 15) + 'px',
       'line-height': _vm.size + 'px'
    })},[(!_vm.entry.has_avatar)?_c('span',[_vm._v(" "+_vm._s(_vm.generateAvatar(_vm.entry))+" ")]):_c('span',[_c('img',{attrs:{"src":_vm.getThumbnailPath(_vm.entry)}})])]):_vm._e(),(!_vm.onlyAvatar)?_c('span',{staticClass:"flexrow-item"},[_vm._v(" "+_vm._s(_vm.entry.name)+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }