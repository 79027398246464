var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ field: _vm.withMargin, 'is-inline': _vm.isInline }},[(_vm.label.length > 0)?_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('p',{staticClass:"control",class:{ 'is-inline': _vm.isInline }},[_c('span',{class:{
        select: true,
        'is-top': this.isTop,
      }},[_c('select',{ref:"select",class:{
          thin: _vm.thin,
          'select-input': true,
          error: this.error
        },style:({
          width: _vm.width ? _vm.width + 'px' : undefined
        }),attrs:{"disabled":_vm.disabled},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.emitEnter()},"change":_vm.updateValue}},_vm._l((_vm.options),function(option,i){return _c('option',{key:(i + "-" + (option.label) + "-" + (option.value)),domProps:{"value":option.value || option.label,"selected":_vm.value === option.value}},[_vm._v(" "+_vm._s(_vm.getOptionLabel(option))+" ")])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }